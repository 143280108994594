<template>
    <div>
        <div style="padding:0.1rem;line-height:0.25rem">您的OA办文总数打败了公司 <span style="color:#3A75C5;font-size:0.17rem">65%</span>的同事，感谢您为投控付出的辛苦工作.</div>
        <div class="chart-wrapper" style="position:relative;top:-20px;">
            <bar-chart :optionData="barChartOption"></bar-chart>
        </div>
    </div>
</template>
<script>
    import BarChart from '@/views/components/dashboard/BarChart'
    export default {
        components:{
           BarChart
        },
        data(){
            return{
                barChartOption:{
                    series: [
                        {
                            data: [120, 165, 30],
                            type: 'bar',
                            barWidth: 25,
                            name:'已办件数',
                            itemStyle: {
                                normal: {
                                    label: {
									show: true, //开启显示
									position: 'top', //在上方显示
									textStyle: { //数值样式
										color: '#3A75C5',
										fontSize: 15
									}
								},
                                color: '#3A75C5'
                                }
                            }
                        }
                    ]
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>