<template>
  <div :class="className" :style="{height:height,width:width}"></div>
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'lineChart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    optionData: {
      type: Object,
      default:function(){
        return {}
      }
    }
  },
  data() {
    return {
      lineChart: null
    }
  },
  watch: {
    optionData: {
      deep: true,
      handler() {
        this.setOptions()
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.lineChart) {
      return
    }
    window.removeEventListener("resize",this.hanlderResize(),true);
    this.lineChart.dispose()
    this.lineChart = null
  },
  methods: {
    initChart() {
      this.lineChart = echarts.init(this.$el, 'macarons');
      this.setOptions();
      window.addEventListener("resize",this.hanlderResize(),true);
    },
    hanlderResize(){
      this.lineChart.resize();
    },
    setOptions() {
      this.lineChart.setOption({
        legend:{
          data:['已办件数'],
          x:'right',
          top:20,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['2020年', '2021年', '2022年'],
          axisTick:{   
            show :false
          },
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#8C8C8C',//左边线的颜色
            }
          },
          axisLabel: {
              textStyle: {
                color: '#8C8C8C',//坐标值得具体的颜色
              }
          },
        },
        yAxis: {
          type: 'value',
          axisTick:{   
            show :false
          },
          axisLine: {
            lineStyle: {
              type: 'solid',
              color: '#8C8C8C',//左边线的颜色
            }
          },
          axisLabel: {
              textStyle: {
                color: '#8C8C8C',//坐标值得具体的颜色
              }
          },
        },
        series: this.optionData.series
      })
    }
  }
}
</script>
